// import { Link, NavLink, useParams } from "react-router-dom";
// import { news } from "../data/bec.js";
// import { BreadCrumbs } from "../bloc/breadСrumbs.jsx";
// import "../css/newsPages.css"
// import { useState } from "react";
// export default function NewsPages() {
//   const params = useParams();
//   const [first, setfirst] = useState(null)
//   console.log(params);
//   let id = params.id;


//   function open(id) {
//     setfirst(id);
    
//   }


  
//   return (
//     <main className="newsPages">
//       <div className="container">
//         <section>
//         <div className="BreadCrumbs">
//             <NavLink to="/" className="breadCrumbs_link">
//               <img src={process.env.PUBLIC_URL + "/img/home.png"} alt="" />
//             </NavLink>
//             <Link to="/news" className="breadCrumbs_link">
//               Новости
//             </Link>
//           </div>
//         <h1>{news[id].text}</h1>
//         <div className="newsPages_body">
//             <div>
//                 <p className="newsPages_textBig" dangerouslySetInnerHTML={{ __html: news[id].textBig }}>
//                 </p>
//             </div>
//             <div>
//                 <p className="newsPages_dates">
//                 <b>{news[id].data}</b>
//                 </p>
//                 <img className="newsPages_prev" src={process.env.PUBLIC_URL + news[id].img} alt="" />
//             </div>
//         </div>
//         </section>
//        {news[id].imgGallari!==undefined?
        
//         <section>
//         <h2>Фотогалерея</h2>
//         <div className="newsPages_gallari">
//             {news[id].imgGallari.map((el,id) =>(
//               <div onClick={()=>open(process.env.PUBLIC_URL + el)} key={id} className="newsPages_gallari_item" style={{background: `url(${el})`}}></div>
//             ))

//             }
//         </div>
//     </section>:""
//        }
//       </div>
//       <Popup id={first} setfirst={setfirst}/>
//     </main>
//   );
// }
// function Popup({id,setfirst}) {
//     return(
//         <div onClick={()=>setfirst(null)} className={id?"popup_img active":"popup_img "}>
//             <div >
//             <img  src={id} alt="" />
//             </div>
//         </div>
//     )
// }


import { Link, NavLink, useParams } from "react-router-dom";
import { news } from "../data/bec.js";
import { BreadCrumbs } from "../bloc/breadСrumbs.jsx";
import "../css/newsPages.css";
import { useState, useRef, useEffect } from "react";

export default function NewsPages() {
  const params = useParams();
  const [first, setFirst] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const galleryContainerRef = useRef(null);

  console.log(params);
  let id = params.id;

  const open = (url) => {
    setFirst(url);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? news[id].imgGallari.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === news[id].imgGallari.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (galleryContainerRef.current) {
      const galleryContainer = galleryContainerRef.current;
      const activeItem = galleryContainer.children[currentIndex];
      galleryContainer.scrollLeft =
        activeItem.offsetLeft - galleryContainer.offsetLeft;
    }
  }, [currentIndex]);

  return (
    <main className="newsPages">
      <div className="container">
        <section>
          <div className="BreadCrumbs">
            <NavLink to="/" className="breadCrumbs_link">
              <img src={process.env.PUBLIC_URL + "/img/home.png"} alt="" />
            </NavLink>
            <Link to="/news" className="breadCrumbs_link">
              Новости
            </Link>
          </div>
          <h1>{news[id].text}</h1>
          <div className="newsPages_body">
            <div>
              <p
                className="newsPages_textBig"
                dangerouslySetInnerHTML={{ __html: news[id].textBig }}
              ></p>
            </div>
            <div>
              <p className="newsPages_dates">
                <b>{news[id].data}</b>
              </p>
              <img
                className="newsPages_prev"
                src={process.env.PUBLIC_URL + news[id].img}
                alt=""
              />
            </div>
          </div>
        </section>

        {news[id].imgGallari && news[id].imgGallari.length > 0 && (
        <section className="photo-gallery-section">
          <div className="photo-gallery-controls">
            <h2>Фотогалерея</h2>
            <div>
              <button onClick={handlePrevClick}><img class="news_gallery_arrow" src="/img/arrow_back.svg" alt="" /></button>
              <button onClick={handleNextClick}><img class="news_gallery_arrow" src="/img/arrow_forward.svg" alt="" /></button>
            </div>
          </div>
          <div className="gallery-container" ref={galleryContainerRef}>
            {news[id].imgGallari.map((el, index) => (
              <div style={{overflow:"hidden"}}>
                <div
                  className={`gallery-item ${index === currentIndex ? "active" : ""}`}
                  onClick={() => open(process.env.PUBLIC_URL + el)}
                  style={{ backgroundImage: `url("${process.env.PUBLIC_URL + el}")` }}
                ></div>
              </div>  
            ))}
          </div>
        </section>
      )}

      </div>
      <Popup id={first} setFirst={setFirst} />
    </main>
  );
}

function Popup({ id, setFirst }) {
  return (
    <div
      onClick={() => setFirst(null)}
      className={id ? "popup_img active" : "popup_img "}
    >
      <div>
        <img src={id} alt="" />
      </div>
    </div>
  );
}
