import { useEffect } from "react";



export default function Ani() {

    useEffect(() => {
        function onAni(entry) {
            entry.forEach(change => {
              if (change.isIntersecting) {
               change.target.classList.add('show');
              }
              
          
            });
          }
          
          let options = {
            threshold: [0.5] };
          let observer = new IntersectionObserver(onAni, options);
          let elements = document.querySelectorAll('.ani');
          // console.log(elements);
          for (let elm of elements) {
            observer.observe(elm);
          }
    }, [document.querySelectorAll('.ani')])
    
   
}