import { useSelector } from "react-redux";
import { Katalog } from "../bloc/katalog";
import { todosSelector } from "../stor/selectors";
import Ani from "../bloc/ani";


export function Katalogs() {
    // из-за того что роутинг происходит посредством прорисовки новых элементов а не переходом на новую страницу тайтл на разнае страницы прописывать можно так
    document.title = 'Каталог продукции - СпецАвто';
    return(
        <main>

            <Katalog/>
            <Ani/>

        </main>
    )
}