import { Link, NavLink } from "react-router-dom";
import "../css/header.css";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { todosSelector } from "../stor/selectors";

export function Header(params) {
  const [menu, setmenu] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(0);
  const [lang, setlang] = useState("ru");
  const data =useSelector(todosSelector);
  const first = useRef(null)
  
  function exit() {
    setmenu3(0);

    setmenu2(false);
    setmenu(false);
  }
  let a = false;
  function Local() {
  }
  useEffect(() => {
    
  
    
  }, [])

  
  return (
    <header className="header">
      <div className="container">
        <div className="header-left">
          <Link className="header_logo" to="/">
          <img
            src={process.env.PUBLIC_URL + "/logoDefult.png"}
            alt="Спецавто Логотип"
          />
          
          </Link>
          <h1 className="header_slogan">
          <span>ПРОИЗВОДСТВО</span> <br /> СПЕЦАВТОМОБИЛЕЙ и ПРИЦЕПОВ
          </h1>
           {/* <div    className="header-right__lang-group lang-group ">
            {localStorage.getItem("lang")==="ru"?
           <img  className="lang " src={process.env.PUBLIC_URL + "/img/ru.png"} alt="" />:
          <img  data-google-lang="en" className="lang " src={process.env.PUBLIC_URL + "/img/eng.png"} alt="" />

          }

            <div  className="lang-group2">
            <img onClick={()=>localStorage.setItem("lang","ru")} data-google-lang="ru" className="lang lang-switcher-a" src={process.env.PUBLIC_URL + "/img/ru.png"} alt="" />
          <img ref={first} id="en" onClick={()=>localStorage.setItem("lang","en")} data-google-lang="en" className="lang lang-switcher-a" src={process.env.PUBLIC_URL + "/img/eng.png"} alt="" />

            </div>
        </div> */}
        </div>
       
       
        <div className="header-right">
          <div className="header-contact">
          <a className="header-right__phone" href="tel:+79603721993">
          <span>+</span>7 (960) 372-19-93
          </a>
          <a className="header-right__mail" href="mailto:mail@specavto.com">mail@specavto.com</a>
          </div>
          <div className="header-contact">
          <a className="header-right__phone" href="tel:+79063902544">
            <span>+</span>7 (906) 390-25-44
          </a>
          <NavLink to="/kontact" className="header-right__location">Ульяновск</NavLink>

          </div>
          <p></p>
          <button onClick={()=>params.setfos(!params.fos)} className="header-right__button">
            <img src={process.env.PUBLIC_URL + "/img/mail.svg"} alt="" />
            <span>Написать нам</span>
          </button>
          <div className="header-contact_link">
              <a target="_blank" href="https://vk.com/specavto73">
                <img src={process.env.PUBLIC_URL + "/img/vkDefult.svg"}  alt="" />
              </a>
              <a target="_blank" href="https://t.me/specavto173">
                <img src={process.env.PUBLIC_URL + "/img/tgDefult.svg"}  alt="" />
              </a>
              {/* <a  target="_blank" href="http://">
                <img src={process.env.PUBLIC_URL + "/img/tg.svg"}  alt="" />
              </a> */}
              <a  target="_blank" href="https://wa.me/+79063902544">
                <img src={process.env.PUBLIC_URL + "/img/watsapDefult.png"}  alt="" />
              </a>
          </div>
          <div className="header-right__menu">
            <div onClick={()=>setmenu(!menu)} id="menu-bar">
              <div id="bar1" className="bar"></div>
              <div id="bar2" className="bar"></div>
              <div id="bar3" className="bar"></div>
            </div>
            <div className="header-right__menu-text">Меню</div>
          </div>
         
        </div>
      </div>


      <div onClick={exit} className={menu?"menu active":"menu"}>
        <div onClick={(e)=>e.stopPropagation()} className=" menu_list menu_list-1">
          <img onClick={exit} className="exit_menu" src={process.env.PUBLIC_URL + "/strel.svg"}  alt="" />
          <nav>
            <Link onClick={exit} to="/kompani">О КОМПАНИИ</Link>
            <p className={menu2?"orange":""} onClick={()=>setmenu2(!menu2)}>МОДЕЛЬНЫЙ РЯД</p>
            <a  onClick={exit} href="/news">НОВОСТИ</a>
            <Link  onClick={exit} to="/kontact">КОНТАКТЫ</Link>
          </nav>
          {/* <div style={{backgroundImage:`url(${!document.querySelector("font")!=null?process.env.PUBLIC_URL + "/img/ru.png":process.env.PUBLIC_URL + "/img/eng.png"})`}} value="en" className="header-right__lang-group lang-group ">
            <div  className="lang-group2">
            <img onClick={()=>setlang("ru")} data-google-lang="ru" className="lang" src={process.env.PUBLIC_URL + "/img/ru.png"} alt="" />
          <img onClick={()=>setlang("eng")} data-google-lang="en" className="lang" src={process.env.PUBLIC_URL + "/img/eng.png"} alt="" />

            </div>
        </div> */}
          <a className="header-right__phone" href="tel:+79063902544">
            <span>+</span>7(906)390-25-44
          </a>
          <p className="menu_sity">Ульяновск</p>
          <a className="mail" href="mailto:mail@specavto.com">mail@specavto.com</a>
          <div className="menu_contact">
          <a className="footer-menu_contact " target="_blank" href="https://vk.com/specavto73">
              <img
                src={process.env.PUBLIC_URL + "/img/vk.svg"}
                alt="Наша группа В Контакте"
              />
            </a>
            <a className="footer-menu_contact " target="_blank" href="https://t.me/specavto173">
              <img
                src={process.env.PUBLIC_URL + "/img/tgDefult.svg"}
                alt="Наша группа В Телеграме"
              />
            </a>
            {/* <a target="_blank" className="footer-menu_contact" href="/">
              <img
                src={process.env.PUBLIC_URL + "/img/tg.svg"}
                alt="Наш канал в Telegramm"
              />
            </a> */}
            <a target="_blank" href="https://wa.me/+79063902544" className="footer-menu_contact">
              <img
                src={process.env.PUBLIC_URL + "/img/watsapDefult.png"}
                alt="Связаться с нами в WatsApp"
              />
            </a>
          </div>
        </div>
        <div onClick={(e)=>e.stopPropagation()} className={menu2?" menu_list menu_list-2 active":" menu_list menu_list-2"}>
          <img onClick={()=>setmenu2(false)} className="exit_menu" src={process.env.PUBLIC_URL + "/strel.svg"}  alt="" />

          <nav>
            <p onClick={()=>setmenu3(1)} >УАЗ</p>
            <p onClick={()=>setmenu3(2)}>ГАЗ</p>
            <p onClick={()=>setmenu3(3)}>ПРИЦЕПЫ</p>

          </nav>
          
          
        </div>
        <div onClick={(e)=>e.stopPropagation()} className={menu3===1?" menu_list menu_list-3 active":" menu_list menu_list-3"}>
        <img onClick={()=>setmenu3(0)} className="exit_menu" src={process.env.PUBLIC_URL + "/strel.svg"}  alt="" />
          
          <nav>
          {data.all&&
          data.all.map((e,i)=>(
            <Link onClick={exit} to={`/katalog/uaz/${e.translit}`}  key={i} className="footer-list_items_link">
              {e.class + " "}<span className="orange">{e.cat}-</span>{e.model}
            </Link>
        ))}

          </nav>
          
          
        </div>
      </div>
    </header>
  );
}




