import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { todosSelector } from "../stor/selectors";
import Preloader from "./preloader";

export function Katalog() {
  const [kat, setKat] = useState(1);
  const [es, setes] = useState(true);
  const [count, setcount] = useState(1);
  const [mass, setmass] = useState(null);

  const data = useSelector(todosSelector);

  let arq = [];

  
  if (!data.all) {
    return <Preloader />;
  }

  function massFil(params) {
    if (count === 1) {
      if (window.screen.width > 1400) {
        for (let i = 0; i < 8; i++) {
          if (data.all[i]) {
            arq[i] = data.all[i];
          }
        }
      } else if (window.screen.width < 500) {
        for (let i = 0; i < 3; i++) {
          if (data.all[i]) {
            arq[i] = data.all[i];
          }
        }
      } else {
        for (let i = 0; i < 6; i++) {
          if (data.all[i]) {
            arq[i] = data.all[i];
          }
        }
      }
    } else {
      for (let i = 0; i < data.all.length; i++) {
        if (data.all[i]) {
          arq[i] = data.all[i];
        }
      }
    }
  }
  massFil();
  return (
    <section className="katalog">
      <div className="container">
        <h1 className="ani ani-1">каталог спецавтомобилей и прицепов</h1>
        <div className="katalog-body">
          <div className="katalog-chek ani ani-4">
            <div
              className={
                kat === 1 ? "katalog-chek_box active" : "katalog-chek_box"
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/img/katalog/yaz.png"}
                alt="gaz-logo"
              />
            </div>
            <div
              className={
                kat === 2 ? "katalog-chek_box active" : "katalog-chek_box"
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/img/katalog/gaz.png"}
                alt="gaz-logo"
              />
            </div>
            <div
              className={
                kat === 3 ? "katalog-chek_box active" : "katalog-chek_box"
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/img/katalog/pricep.png"}
                alt="gaz-logo"
              />
            </div>
          </div>

          <div className="katalog-slider">
            <div className="katalog-list ani ani-1 ">
              {arq.map((e, i) => (
                <ListItem
                  key={e.id}
                  kat={e.cat}
                  img={e.photo}
                  name={e.class}
                  translit={e.translit}
                  marka={e.model}
                />
              ))}
            </div>
            {count === 1 ? (
              <div className="katalog-list_button">
                <button
                  className="header-right__button"
                  onClick={() => setcount(2)}
                >
                  <span>Показать все</span>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
function ListItem({ img, name, marka, kat, translit }) {
  return (
    <NavLink
      to={`/katalog/uaz/${translit}`}
      className={"katalog-list_item"}
    >
      <div className="katalog-list_item__img">
        <img
          src={process.env.PUBLIC_URL + `https://specavto.agatech.ru//img/katalog/galery/${img}`}
          alt={name}
        />
      </div>
      <div className="katalog-list_item__text">
        <h3>{name}</h3>
        <p>
          {marka} {kat}
        </p>
      </div>
    </NavLink>
  );
}
