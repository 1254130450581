export default function Polzovatelskoe(params) {
    return (
        <main className="polsovatelskoe">
            <div className="container">
                <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
                <div>
                    <h3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.</h3>
                    <ul>
                        <li>
                            1.1. «Компания» - ООО «Спецавто»
                            (ИНН: 7327025655; Юридический
                            адрес: 432045, г. Ульяновск, ул.
                            Московское шоссе, д.3
                        </li>
                        <li>
                            1.2. «Сайт» - сайт Компании,
                            размещенный в сети Интернет, по
                            адресу: https://specavto.com
                        </li>
                        <li>
                            1.3. «Пользователь» – лицо,
                            имеющее доступ к Сайту,
                            посредством сети Интернет.
                        </li>
                        <li>
                            1.4. «Персональные данные
                            Пользователя» - персональная
                            информация, относящаяся к прямо
                            или косвенно определенному, или
                            определяемому физическому лицу
                            (Пользователю) и полученная
                            Компанией в процессе
                            использования Пользователем
                            Сайта, а также информация,
                            полученная Сайтом автоматически
                            с помощью установленного на
                            устройстве Пользователя
                            программного обеспечения, в том
                            числе данные файлов «Cookies».
                        </li>
                        <li>
                            1.5. «Партнер» - третье лицо,
                            состоящее с Компанией в
                            договорных отношениях, которому
                            могут быть переданы Персональные
                            данные Пользователя в заявленных
                            в настоящей Политике
                            конфиденциальности и в согласии
                            Пользователя целях.
                        </li>
                        <li>
                            1.6. «Cookies» - это файлы,
                            которые позволяют сохранять
                            определенную информацию о
                            Пользователях и устройствах
                            Пользователей при посещении
                            Сайта.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ.</h3>
                    <ul>
                        <li>
                            2.1. Настоящая Политика
                            конфиденциальности
                            регламентирует правила обработки
                            Компанией Персональных данных
                            Пользователей при посещении ими
                            Сайта.
                        </li>
                        <li>
                            2.2. Настоящая Политика
                            конфиденциальности создана в
                            соответствии с действующим
                            законодательством Российской
                            Федерации о персональных данных,
                            а также в соответствии с
                            принятыми в Компании политиками,
                            положениями и стандартами, в
                            частности с Политикой в
                            отношении обработки персональных
                            данных.
                        </li>
                        <li>
                            2.3. Настоящая Политика
                            конфиденциальности является
                            открытым и общедоступным
                            документом. Действующая редакция
                            Политики конфиденциальности
                            размещена на Сайте Компании.
                        </li>
                        <li>
                            2.4. Используя Сайт,
                            Пользователь выражает свое
                            согласие с условиями настоящей
                            Политики конфиденциальности, а
                            также подтверждает свое право- и
                            дееспособность.
                        </li>
                        <li>
                            2.5. Компания не несет
                            ответственности за действия
                            третьих лиц, совершенные ими с
                            Персональными данными
                            Пользователя, если такие третьи
                            лица получили персональные
                            данные Пользователей
                            самостоятельно непосредственно
                            от Пользователя без участия
                            Компании и/или из открытых
                            источников и/или от третьих лиц
                            без участия Компании на законных
                            основаниях.
                        </li>
                        <li>
                            2.6. Обращения, предложения и
                            претензии, связанные с
                            содержанием и функционированием
                            Сайта, нарушениями прав и
                            интересов третьих лиц,
                            требований законодательства
                            Российской Федерации, а также
                            для запросов уполномоченных
                            законодательством Российской
                            Федерации лиц могут быть
                            направлены по электронному
                            адресу mail@specavto.com или по
                            адресу Компании: 432045, г.
                            Ульяновск, ул. Московское шоссе,
                            д.3
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>
                        3. ЦЕЛИ И УСЛОВИЯ ОБРАБОТКИ
                        ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ.
                    </h3>
                    <ul>
                        <li>
                            3.1. На Сайте обрабатываются
                            Cookies. Они помогают определить
                            количество посетителей Сайта,
                            частоту посещений Сайта, а также
                            помогают адаптировать
                            предложения Сайта под
                            индивидуальные запросы
                            Пользователя. Продолжая
                            использовать Сайт, Пользователь
                            дает свое согласие на обработку
                            Компанией Cookies. «Cookies»
                            хранятся на жестком диске
                            устройства Пользователя.
                            Пользователь может
                            самостоятельно деактивировать
                            сохранение Cookies на своем
                            устройстве, изменив настройки
                            своего браузера, с помощью
                            которого он получает доступ в
                            Интернет. Также Пользователь
                            может в любой момент удалить
                            Cookies с жесткого диска своего
                            устройства, с помощью которого
                            он посещает Сайт.
                        </li>
                        <li>
                            3.2. На Сайте размещены формы
                            сбора персональных данных (далее
                            – Формы сбора). Формы сбора
                            предназначены для использования
                            Пользователями, посещающими Сайт
                            с территории Российской
                            Федерации. Пользователь
                            добровольно и самостоятельно
                            может вносить свои персональные
                            данные в Формы сбора в
                            запрашиваемом соответствующей
                            Формой сбора объеме.
                            Пользователь несет
                            ответственность за корректность
                            и точность предоставленных
                            персональных данных.
                            Пользователь принимает на себя
                            все возможные риски, связанные с
                            его действиями по допущению
                            ошибок, неточностей в
                            предоставленных данных.
                        </li>
                        <li>
                            3.3. Каждый раз при заполнениях
                            Форм сбора на Сайте у
                            Пользователя запрашивается
                            согласие на обработку внесенных
                            Пользователем персональных
                            данных, которое содержит
                            информацию, в том числе, о
                            порядке и способах обработки
                            данных, о сроке обработки, о
                            возможности отзыва согласия.
                            Если это необходимо для целей
                            обработки запроса Пользователя,
                            направленного через Сайт
                            посредством заполнения Формы
                            сбора, внесенные Пользователем
                            персональные данные могут быть
                            доступны Партнерам Компании
                            исключительно в целях,
                            предусмотренных настоящей
                            Политикой и соответствующим
                            согласием Пользователя. Данные
                            Пользователей доступны Партнеру
                            при посещении Пользователями
                            Сайта и при заполнении
                            Пользователями Форм.
                        </li>
                        <li>
                            3.4. По отдельному согласию
                            Пользователя Компания вправе
                            обрабатывать персональные данные
                            Пользователя, внесенные
                            Пользователем в Форму сбора, в
                            целях: учета персональных данных
                            Пользователей в базах данных
                            Компании; проведения
                            статистических исследований, а
                            также исследований, направленных
                            на улучшение качества продукции
                            и услуг; проведения
                            маркетинговых программ, в том
                            числе, для продвижения товаров,
                            работ, услуг на рынке;
                            информирования Пользователей о
                            новых товарах и услугах
                            Компании. Для обработки
                            персональных данных в заявленных
                            в настоящем пункте 3.4 целях
                            Компания вправе привлекать
                            Партнеров при условии, что такие
                            Партнеры приняли на себя
                            обязательства по обеспечению
                            безопасности и
                            конфиденциальности Персональных
                            данных Пользователей.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>
                        4. ХРАНЕНИЕ И ОБРАБОТКА ПЕРСОНАЛЬНЫХ
                        ДАННЫХ ПОЛЬЗОВАТЕЛЯ КОМПАНИЕЙ.
                    </h3>
                    <ul>
                        <li>
                            4.1. Компания обеспечивает
                            первичный сбор и хранение
                            Персональных данных
                            Пользователей в базе данных,
                            размещенной на территории
                            Российской Федерации. Хранение
                            данных производится по адресу
                            Компании.
                        </li>
                        <li>
                            4.2. Обработка Персональных
                            данных Пользователей
                            осуществляется в заявленных в
                            настоящей Политике
                            конфиденциальности целях.
                        </li>
                        <li>
                            4.3. Персональные данные
                            Пользователей, полученные
                            Компанией в целях, указанных в
                            п. 3.3. настоящей Политики
                            конфиденциальности,
                            обрабатываются до момента
                            выполнения соответствующего
                            запроса Пользователя,
                            направленного Пользователем
                            через Сайт.
                        </li>
                        <li>
                            4.4. Персональные данные
                            Пользователя, полученные
                            Компанией в целях, указанных в
                            п. 3.4 настоящей Политики
                            конфиденциальности,
                            обрабатываются до момента отзыва
                            Пользователем своего согласия на
                            получение рекламной информации
                            согласно п. 3.4 настоящей
                            Политики конфиденциальности.
                        </li>
                        <li>
                            4.5. В соответствии со статьей 9
                            Федерального закона от
                            27.07.2006 года №152-ФЗ «О
                            персональных данных»
                            Пользователь вправе в любое
                            время направить в Компанию
                            требование об исключении или
                            исправлении (дополнении)
                            неверных или неполных
                            персональных данных, а также об
                            отзыве данного Пользователем
                            согласно п. 3.3. и п. 3.4.
                            настоящей Политики
                            конфиденциальности согласия на
                            обработку персональных данных.
                            Указанное требование может быть
                            направлено Пользователем в
                            Компанию в виде соответствующего
                            письменного заявления заказным
                            письмом с описью вложения по
                            адресу Компании или в
                            электронной форме посредством
                            направления письма по адресу
                            info@magmasimbirsk.ru
                        </li>
                        <li>
                            4.6. Компания теряет возможность
                            обработать направленный
                            Пользователем через Сайт запрос
                            согласно п. 3.3. настоящей
                            Политики конфиденциальности в
                            случае отзыва Пользователем
                            согласия на обработку
                            персональных данных Пользователя
                            до момента решения Компанией
                            указанного запроса Пользователя.
                            Отзывая свое согласие на
                            обработку персональных данных до
                            момента решения своего запроса,
                            Пользователь принимает на себя
                            все возможные риски, связанные с
                            невозможностью Компании
                            связаться с Пользователем для
                            решения его запроса.
                        </li>
                        <li>
                            4.7. Деятельность Компании не
                            направлена на целенаправленное
                            взаимодействие с лицами,
                            находящимися на территории
                            действия норм GDPR (Европейского
                            регламента по обработке
                            персональных данных), путем
                            предложения указанным лицам
                            товаров или услуг либо
                            мониторинга их действий.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>
                        5.ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ.
                    </h3>
                    <ul>
                        <li>
                            5.1. Пользователь обязуется
                            надлежащим образом соблюдать
                            условия настоящей Политики.
                        </li>
                        <li>
                            5.2. Пользователь вправе
                            использовать Сайт с целью
                            личного некоммерческого
                            использования.
                        </li>
                        <li>
                            5.3. Пользователь обязуется не
                            использовать Сайт в нарушение
                            прав и законных интересов
                            Компании, иных правообладателей,
                            третьих лиц, условий настоящей
                            Политики и законодательства РФ.
                        </li>
                        <li>
                            5.4. Пользователь обязуется
                            принимать надлежащие меры для
                            обеспечения сохранности его
                            устройства и несет личную
                            ответственность в случае доступа
                            к его устройству третьих лиц.
                        </li>
                        <li>
                            5.5. Пользователь не вправе
                            осуществлять любые
                            несанкционированные действия с
                            Сайтом.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>
                        6. ПРАВА И ОБЯЗАННОСТИ КОМПАНИИ.
                    </h3>
                    <ul>
                        <li>
                            6.1. Компания вправе в любое
                            время в одностороннем порядке
                            вносить изменения в настоящую
                            Политику конфиденциальности. При
                            внесении изменений в Политику
                            конфиденциальности новая
                            редакция Политики
                            конфиденциальности размещается
                            на Сайте Компании с указанием
                            даты вступления в силу новой
                            редакции.
                        </li>
                        <li>
                            6.2. Компания вправе
                            обрабатывать полученные через
                            Сайт Персональные данные
                            Пользователей в порядке и на
                            условиях, указанных в настоящей
                            Политике конфиденциальности, а
                            также в соответствии с
                            требованиями действующего
                            законодательства Российской
                            Федерации о персональных данных
                            и внутренних политик, процедур и
                            положений, принятый в Компании в
                            целях соблюдения
                            законодательства РФ о
                            персональных данных.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>7. ССЫЛКИ НА САЙТЫ ТРЕТЬИХ ЛИЦ.</h3>
                    <ul>
                        <li>
                            7.1. Сайт может содержать ссылки
                            на другие сайты в сети Интернет,
                            в том числе на сайты,
                            зарегистрированные за пределами
                            зоны «.RU». С момента перехода
                            Пользователя на сайты третьих
                            лиц действие настоящей Политики
                            конфиденциальности прекращается.
                            Обработка персональных данных
                            Пользователя на сайтах третьих
                            лиц осуществляется в
                            соответствии с установленными на
                            таких сайтах правилами третьих
                            лиц, за правомерность которых
                            Компания ответственности не
                            несет. 7.2. Компания не несет
                            ответственность за информацию
                            или контент, размещенные на
                            сайтах третьих лиц.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ.</h3>
                    <ul>
                        <li>
                            8.1. Настоящая Политика
                            конфиденциальности составлена на
                            русском языке и в соответствии с
                            законодательством Российской
                            Федерации.
                        </li>
                        <li>
                            8.2. Если какое-либо из
                            положений настоящей Политики
                            конфиденциальности будет
                            признано недействительным, это
                            не оказывает влияния на
                            действительность или
                            применимость остальных положений
                            настоящей Политики
                            конфиденциальности.
                        </li>
                    </ul>
                </div>
            </div>
        </main>
    );
}
