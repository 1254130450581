import { useState } from "react";

export function Gallari(params) {
    const [count, setcount] = useState(1);
  const [first, setfirst] = useState(null)
  function open(id) {
    setfirst(id);
    
  }
    const slide_1 = [
        "/img/gallari/slide_1/1.png",
        "/img/gallari/slide_1/2.png",
        "/img/gallari/slide_1/3.png",
        "/img/gallari/slide_1/4.png",
        "/img/gallari/slide_1/5.png",
        "/img/gallari/slide_1/6.png",
        "/img/gallari/slide_1/7.png",
        "/img/gallari/slide_1/8.png",
    ];
    function Steps(params) {

        let imgss = document.querySelectorAll(".gallari_item.active .gallari_item_boxList_img")
        let cost = imgss.length-1;
        // imgss.forEach(element => {
        // });
        let timer = setInterval(() => {
            if (!(cost<0)) {
            imgss[cost].classList.add("active");

                cost--;
            }
        }, 200);



        setTimeout(() => {
            if (count + params < 1) {
                setcount(
                    document.querySelectorAll(".gallari_item")
                        .length
                );
            } else if (
                count + params >
                document.querySelectorAll(".gallari_item")
                    .length
            ) {
                setcount(1);
            } else {
                setcount(count + params);
            }
            imgss.forEach(element => {
                element.classList.remove("active");
            });
            clearTimeout(timer);
        }, 3300);
        
    }
    return (
        <section className="gallari">
            <div className="container">
                <h2 className="ani ani-1">
                    Автомобили нашего модельного ряда
                </h2>
                <div className="gallari_items ani ani-3 ">
                    <div
                        onClick={() => Steps(-1)}
                        className="gallari_items_er left"
                    >
                        &lt;
                    </div>

                    <div
                        className={
                            count === 1
                                ? "gallari_item active"
                                : "gallari_item "
                        }
                    >
                         <div className="gallari_item_boxList">
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/1.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/2.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/3.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/4.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img  className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/5.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/6.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/7.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/7.png"
                                    }
                                    alt=""
                                />
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/8.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_3/8.png"
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        <h3 className="gallari_item_name">
                        молоковоз 362213 (УАЗ)  
                        </h3>
                    </div>
                    <div
                        className={
                            count === 2
                                ? "gallari_item active"
                                : "gallari_item "
                        }
                    >
                        <div className="gallari_item_boxList">
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/1.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/2.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/3.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/4.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img  className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/5.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/6.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/7.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/7.png"
                                    }
                                    alt=""
                                />
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/8.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_2/8.png"
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        <h3 className="gallari_item_name">
                            пожарный автомобиль 362223 (УАЗ)
                        </h3>
                    </div>
                    <div
                        className={
                            count === 3
                                ? "gallari_item active"
                                : "gallari_item "
                        }
                    >
                        <div className="gallari_item_boxList">
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/1.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/2.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/3.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/4.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img  className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/5.png"
                                    }
                                    alt=""
                                />
                                <img className="gallari_item_boxList_img"
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/6.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="gallari_item_boxList_minbox">
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/7.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img pGallery "
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/7.png"
                                    }
                                    alt=""
                                />
                                <img onClick={()=>open(process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/8.png")} className="gallari_item_boxList_img n_gallari_item_boxList_img pGallery "
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gallari/slide_1/8.png"
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        <h3 className="gallari_item_name">
                        топливозаправщик 362233 (уаз)
                        </h3>
                    </div>
                    <div
                        onClick={() => Steps(+1)}
                        className="gallari_items_er right"
                    >
                        &gt;
                    </div>
                </div>
            </div>
      <Popup id={first} setfirst={setfirst}/>

        </section>
    );
}

function Popup({id,setfirst}) {
    return(
        <div onClick={()=>setfirst(null)} className={id?"popup_img active":"popup_img "}>
            <div >
            <img  src={id} alt="" />
            </div>
        </div>
    )
}

// import { useState } from "react"

// export function Gallari(params) {
//     const [count, setcount] = useState(2);

//       function Steps(params) {
//         if (count+params<1) {
//             setcount(document.querySelectorAll(".gallari_item").length);
//         } else   if (count+params > document.querySelectorAll(".gallari_item").length) {
//             setcount(1);
//         } else{
//             setcount(count +params);
//         }

//       }
//     return(
//         <section className="gallari">
//             <div className="container">
//                 <h2 className="ani ani-1">
//                 Автомобили нашего модельного ряда
//                 </h2>
//                 <div className="gallari_items ani ani-3 ">
//                     <div onClick={()=>Steps(-1)} className="gallari_items_er left">
//                     &lt;
//                     </div>

//                     <div className={count===1?"gallari_item active":"gallari_item "}>
//                     <img src={process.env.PUBLIC_URL + "/img/gallari/1.jpg"} alt="топливозаправщик 362233 (уаз)" />
//                         <h3 className="gallari_item_name">
//                         топливозаправщик 362233 (уаз)
//                         </h3>
//                     </div>
//                     <div  className={count===2?"gallari_item active":"gallari_item "}>
//                     <img src={process.env.PUBLIC_URL + "/img/gallari/3.png"} alt="топливозаправщик 362233 (уаз)" />
//                         <h3 className="gallari_item_name">
//                         пожарный автомобиль 362223 (УАЗ)
//                         </h3>
//                     </div>
//                     <div onClick={()=>Steps(+1)} className="gallari_items_er right">
//                     &gt;
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }
